 'use strict';

/**
 AJAX call to execute IR service for CYO products
 */
function processCYOImages() {
    var imageRecognitionURL = window.UncachedData.imageRecognitionURL;
    if (imageRecognitionURL) {
        $.ajax({
            url: imageRecognitionURL,
            method: 'POST'
        });
    }
}

module.exports = {
    processCYOImages: processCYOImages
};
