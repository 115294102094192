// Extend this file from rvw_autobhan_core for PAP-1118
'use strict';
var base = require('core/components/clientSideValidation');

base.invalid = function () {
    $('form input, form select').on('invalid', function (e) {
        var form = $(this).closest('form');
        e.preventDefault();
        this.setCustomValidity('');
        if (!this.validity.valid) {
            var validationMessage = this.validationMessage;
            $(this).addClass('is-invalid');
            // made change here to set focus on input field if there is error for PAP-1118
            if(this.id === 'login-form-email') {
                $(this).focus();
            } else if (this.id === 'login-form-password') {
                $(this).focus();
                if($('#login-form-email').hasClass('is-invalid')){
                    $('#login-form-email').focus();
                }
            } else if (this.id === 'registration-form-fname') {
                $(this).focus();
            }
            if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                validationMessage = $(this).data('pattern-mismatch');
            }
            if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                && $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if ((this.validity.tooLong || this.validity.tooShort)
                && $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            //PAP-1270: update form validation errors in locale specific language in Add/Edit address in My profile section
            if (form.length > 0) {
                if ((this.validity.tooLong || this.validity.rangeOverflow) && $(this).data('overflow-error')) {
                    var error = $(this).data('overflow-error');
                    error = error.replace('{0}', $(this).attr('maxlength'));
                    error = error.replace('{1}', $(this).val().length);
                    validationMessage = error;
                } else if ((this.validity.tooShort || this.validity.rangeUnderflow) && $(this).data('underflow-error')) {
                    var error = $(this).data('underflow-error');
                    error = error.replace('{0}', $(this).attr('maxlength'));
                    error = error.replace('{1}', $(this).val().length);
                    validationMessage = error;
                }
            }
            if (this.validity.valueMissing && $(this).data('missing-error')) {
                validationMessage = $(this).data('missing-error');
            }
            $(this).parents('.form-group').find('.invalid-feedback')
                .text(validationMessage);
        }
    });
}
module.exports = base;