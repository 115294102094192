'use strict';

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
var consent = {
    getString: (urlAccept, textAccept) => {
        //immediately return a calculate string value
        return `<div class="consent-drawer" id="consent-tracking" role="dialog">
        <div class="consent-text-wrapper"></div>
        <div class="consent-button-wrapper">
        <button class="affirm btn btn-primary" data-url="${urlAccept}" autofocus>
        ${textAccept}
        </button>
        </div>
        </div>`;
    },
    showConsentDrawer: ($trackingElement) => {
        if ([undefined, null].indexOf($trackingElement) !== -1 || !$trackingElement.data('caonline')) {
            return;
        }

        var namespace = '.consenttrackingdrawer ',
            urlContent = $trackingElement.data('url'),
            urlAccept = $trackingElement.data('accept'),
            textAccept = $trackingElement.data('accepttext');

        var htmlString = consent.getString(urlAccept, textAccept);

        $('body').append(htmlString);

        var $consentTracking = $('#consent-tracking');

        $.ajax({
            url: urlContent,
            type: 'get',
            dataType: 'html',
            context: $consentTracking,
            success: function (response) {
                this.find('.consent-text-wrapper').html(response);
            },
            error: function () {
                this.remove();
            }
        });

        $consentTracking.find('.consent-button-wrapper button').off('click').on('click' + namespace, (event) => {
            event.preventDefault();
            var url = $(event.target).data('url');
	        if (url.indexOf('consent=true') > 0) {
	            consent = 'true';
	        } else{
	        	var mcAnalyticsEnabled =  $('.mcEnableAnalytics').data('sfmc-mcenableanalytics');
	       		if (mcAnalyticsEnabled && _etmc) {
					var mcMID =  $('.sfmcMID').data('sfmc-mid');
	                _etmc.push(["setOrgId",mcMID]);
					_etmc.push(["doNotTrack"]);
			    }
	        }
	        
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                context: $consentTracking,
                complete: function () {
                    this.remove();
                },
                error: function () {
                    this.remove();
                }
            });
        });
    },
    init: () => {
        var namespace = '.consenttracking ',
            $body = $('body'),
            $trackingConsent = $('.tracking-consent');

        if ($('.consented').length === 0 && $trackingConsent.hasClass('api-true')) {
            consent.showConsentDrawer($trackingConsent);
        }

        $trackingConsent.each((index, element) => {
            var $this = $(this);

            if ($this.hasClass('api-true')) {
                $this.on('click' + namespace, () => {
                    consent.showConsentDrawer($this);
                });
            }
        });
    }
};

module.exports = consent;
