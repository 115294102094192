"use strict";

var integrations = require('integrations/integrations/adyen/adyenCheckout');

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

// eslint-disable-next-line no-unused-vars
var maskedCardNumber;
var MASKED_CC_PREFIX = '************';
var selectedMethod;
var componentsObj = {};
var checkoutConfiguration = window.Configuration;
var formErrorsExist;
var isValid = false;
var checkout;
$('#dwfrm_billing').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var url = form.attr('action');
  $.ajax({
    type: 'POST',
    url: url,
    data: form.serialize(),
    async: false,
    success: function success(data) {
      formErrorsExist = 'fieldErrors' in data;
    }
  });
});

checkoutConfiguration.onChange = function (state) {
  var type = state.data.paymentMethod.type;
  isValid = state.isValid;

  if (!componentsObj[type]) {
    componentsObj[type] = {};
  }

  componentsObj[type].isValid = isValid;
  componentsObj[type].stateData = state.data;
};

checkoutConfiguration.showPayButton = false;
checkoutConfiguration.paymentMethodsConfiguration = {
  card: {
    enableStoreDetails: showStoreDetails,
    onBrand: function onBrand(brandObject) {
      document.querySelector('#cardType').value = brandObject.brand;
    },
    onFieldValid: function onFieldValid(data) {
      if (data.endDigits) {
        maskedCardNumber = MASKED_CC_PREFIX + data.endDigits;
        document.querySelector('#cardNumber').value = maskedCardNumber;
      }
    },
    onChange: function onChange(state) {
      isValid = state.isValid;
      var componentName = state.data.paymentMethod.storedPaymentMethodId ? "storedCard".concat(state.data.paymentMethod.storedPaymentMethodId) : state.data.paymentMethod.type;

      if (componentName === selectedMethod) {
        componentsObj[selectedMethod].isValid = isValid;
        componentsObj[selectedMethod].stateData = state.data;
      }
    }
  },
  /**
  boletobancario: {
    personalDetailsRequired: true,
    // turn personalDetails section on/off
    billingAddressRequired: false,
    // turn billingAddress section on/off
    showEmailAddress: false,
    // allow shopper to specify their email address
    // Optionally prefill some fields, here all fields are filled:
    data: {
      firstName: document.getElementById('shippingFirstNamedefault').value,
      lastName: document.getElementById('shippingLastNamedefault').value
    }
  },
  **/
  paywithgoogle: {
    environment: window.Configuration.environment,
    onSubmit: () => {
      assignPaymentMethodValue();
      document.querySelector('button[value="submit-payment"]').disabled = false;
      document.querySelector('button[value="submit-payment"]').click();
    },
    configuration: {
      gatewayMerchantId: window.merchantAccount
    },
    showPayButton: true,
    buttonColor: 'white'
  },
  paypal: {
    environment: window.Configuration.environment,
    intent: 'capture',
    onSubmit: (state, component) => {
      assignPaymentMethodValue();
      document.querySelector('#adyenStateData').value = JSON.stringify(componentsObj[selectedMethod].stateData);
      paymentFromComponent(state.data, component);
    },
    onCancel: (data, component) => {
      paymentFromComponent({
        cancelTransaction: true
      }, component);
      component.setStatus('ready');
    },
    onError: (error, component) => {
      if (component) {
        component.setStatus('ready');
      }
    },
    onAdditionalDetails: state => {
      document.querySelector('#additionalDetailsHidden').value = JSON.stringify(state.data);
      document.querySelector('#showConfirmationForm').submit();
    },
    onClick: (data, actions) => {
      $('#dwfrm_billing').trigger('submit');

      if (formErrorsExist) {
        return actions.reject();
      }
    }
  }
  /**
  ,
  afterpay_default: {
    visibility: {
      personalDetails: 'editable',
      billingAddress: 'hidden',
      deliveryAddress: 'hidden'
    },
    data: {
      personalDetails: {
        firstName: document.querySelector('#shippingFirstNamedefault').value,
        lastName: document.querySelector('#shippingLastNamedefault').value,
        telephoneNumber: document.querySelector('#shippingPhoneNumberdefault').value,
        shopperEmail: document.querySelector('#email').value
      }
    }
  },
  facilypay_3x: {
    visibility: {
      personalDetails: 'editable',
      billingAddress: 'hidden',
      deliveryAddress: 'hidden'
    },
    data: {
      personalDetails: {
        firstName: document.querySelector('#shippingFirstNamedefault').value,
        lastName: document.querySelector('#shippingLastNamedefault').value,
        telephoneNumber: document.querySelector('#shippingPhoneNumberdefault').value,
        shopperEmail: document.querySelector('#email').value
      }
    }
  }
  **/
};

if (window.installments) {
  try {
    var installments = JSON.parse(window.installments);
    checkoutConfiguration.paymentMethodsConfiguration.card.installments = installments;
  } catch (e) {} // eslint-disable-line no-empty

}

if (window.paypalMerchantID !== 'null') {
  checkoutConfiguration.paymentMethodsConfiguration.paypal.merchantId = window.paypalMerchantID;
}

if (window.googleMerchantID !== 'null' && window.Configuration.environment === 'LIVE') {
  checkoutConfiguration.paymentMethodsConfiguration.paywithgoogle.merchantIdentifier = window.googleMerchantID;
}

/**
 * Changes the "display" attribute of the selected method from hidden to visible
 */
function displaySelectedMethod(type) {
  selectedMethod = type;
  resetPaymentMethod();
  if (type != 'AMAZON_PAY') {
    sessionStorage.setItem('paymentMethodId', '');
    $('.amazon_pay-message').addClass('d-none');
  }

  if (['paypal', 'paywithgoogle'].indexOf(type) > -1) {
    document.querySelector('button[value="submit-payment"]').disabled = true;
  } else {
    document.querySelector('button[value="submit-payment"]').disabled = false;
  }

  if(type == 'PAY_PAL_TAB'){
    $('.paymentMethod #component_scheme').css("display", "none");
    $('.paypal-content.js_paypal-content').css("display", "block");
    $('.adyen-component-content').removeClass('active');
    $('.paypal-content.js_paypal-content').addClass('active tab-pane');
    $('input[name*="billing_paymentMethod"]').val('PayPal');
    if($("#restPaypalAccountsList").val() == 'newaccount' && !$(".credit-card-selection-new").hasClass("d-none")){
      $(".submit-payment").hide();
    } else{
      $(".submit-payment").show();
    }
  }
  var component = document.querySelector("#component_".concat(type));
  //PAP-3145: show component only if not empty to avoid empty spaces
  if(component && component.childNodes.length > 0) {
    component.setAttribute('style', 'display:block');
  }
}

/**
 * To avoid re-rendering components twice, unmounts existing components from payment methods list
 */
function unmountComponents() {
  var promises = Object.entries(componentsObj).map(function (_ref) {
    var [key, val] = _ref;
    delete componentsObj[key];
    return resolveUnmount(key, val);
  });
  return Promise.all(promises);
}

function resolveUnmount(key, val) {
  try {
    return Promise.resolve(val.node.unmount("component_".concat(key)));
  } catch (e) {
    // try/catch block for val.unmount
    return Promise.resolve(false);
  }
}

/**
 * checks if payment method is blocked and returns a boolean accordingly
 */
function isMethodTypeBlocked(methodType) {
  var blockedMethods = ['bcmc_mobile_QR', 'applepay', 'cup', 'wechatpay', 'wechatpay_pos', 'wechatpaySdk', 'wechatpayQr'];
  return blockedMethods.includes(methodType);
}

/**
 * Calls getPaymenMethods and then renders the retrieved payment methods (including card component)
 */

function renderGenericComponent() {
  return _renderGenericComponent.apply(this, arguments);
}

function _renderGenericComponent() {
  _renderGenericComponent = _asyncToGenerator(function* () {
    if (Object.keys(componentsObj).length !== 0) {
      yield unmountComponents();
    }

    getPaymentMethods(function (data) {
      var paymentMethod;
      var i;
      checkoutConfiguration.paymentMethodsResponse = data.AdyenPaymentMethods;

      if (data.amount) {
        checkoutConfiguration.amount = data.amount;
      }

      if (data.countryCode) {
        checkoutConfiguration.countryCode = data.countryCode;
      }

      checkout = new AdyenCheckout(checkoutConfiguration);
      //PAP-3145: remove only adyen payment methods, avoid paypal erase
      $("#paymentMethodsList li").remove();

      if (data.AdyenPaymentMethods.storedPaymentMethods) {
        for (i = 0; i < checkout.paymentMethodsResponse.storedPaymentMethods.length; i++) {
          paymentMethod = checkout.paymentMethodsResponse.storedPaymentMethods[i];

          if (paymentMethod.supportedShopperInteractions.includes('Ecommerce')) {
            renderPaymentMethod(paymentMethod, true, data.ImagePath, null, data.ccVisaLogo, data.ccMCLogo);
          }
        }
      }

      data.AdyenPaymentMethods.paymentMethods.forEach((pm, i) => {
        !isMethodTypeBlocked(pm.type) && renderPaymentMethod(pm, false, data.ImagePath, data.AdyenDescriptions[i].description, data.ccVisaLogo, data.ccMCLogo);
      });

      if (data.AdyenConnectedTerminals && data.AdyenConnectedTerminals.uniqueTerminalIds && data.AdyenConnectedTerminals.uniqueTerminalIds.length > 0) {
        var posTerminals = document.querySelector('#adyenPosTerminals');

        while (posTerminals.firstChild) {
          posTerminals.removeChild(posTerminals.firstChild);
        }

        addPosTerminals(data.AdyenConnectedTerminals.uniqueTerminalIds);
      }

      var firstPaymentMethod = document.querySelector('input[type=radio][name=brandCode]');
      firstPaymentMethod.checked = true;
      displaySelectedMethod(firstPaymentMethod.value);
    });
  });
  return _renderGenericComponent.apply(this, arguments);
}

function renderPaymentMethod(paymentMethod, storedPaymentMethodBool, path, description, ccVisaLogo, ccMCLogo) {
  var description = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var node;
  var paymentMethodsUI = document.querySelector('#paymentMethodsList');
  var isIdeal = paymentMethod.type === 'ideal';
  var li = document.createElement('li');
  var paymentMethodID = storedPaymentMethodBool ? "storedCard".concat(paymentMethod.id) : paymentMethod.type;
  var isSchemeNotStored = paymentMethod.type === 'scheme' && !storedPaymentMethodBool;
  var paymentMethodImage = storedPaymentMethodBool ? "".concat(path).concat(paymentMethod.brand, ".png") : "".concat(path).concat(paymentMethod.type, ".png");
  var cardImage = "".concat(path, "card.png");
  var imagePath = isSchemeNotStored ? cardImage : paymentMethodImage;
  var label = storedPaymentMethodBool ? "".concat(paymentMethod.name, " ").concat(MASKED_CC_PREFIX).concat(paymentMethod.lastFour) : "".concat(paymentMethod.name);
  
  var preOrderClass = "";
  if (paymentMethod.preOrder){
  	preOrderClass="checkout-hidden";
  }
  var liContents = "\n <div class=\"custom-control custom-radio\">    \n <input name=\"brandCode\" class=\" custom-control-input ".concat(preOrderClass , "\" type=\"radio\" value=\"").concat(paymentMethodID, "\" id=\"rb_").concat(paymentMethodID, "\">\n            <label class=\"custom-control-label\" id=\"lb_").concat(paymentMethodID, "\" for=\"rb_").concat(paymentMethodID, "\">").concat(label, "<img class=\"paymentMethod_img\" alt=\"").concat(paymentMethod.name, "\" src=\"").concat(imagePath, "\" ></img>\n </label>\n  </div>");
  //PAP-3143 - update cc logo url
  if (paymentMethod.type === 'scheme' && ccVisaLogo && ccMCLogo) {
    liContents = "\n <div class=\"custom-control custom-radio\">    \n <input name=\"brandCode\" class=\" custom-control-input ".concat(preOrderClass , "\" type=\"radio\" value=\"").concat(paymentMethodID, "\" id=\"rb_").concat(paymentMethodID, "\">\n            <label class=\"custom-control-label\" id=\"lb_").concat(paymentMethodID, "\" for=\"rb_").concat(paymentMethodID, "\">").concat(label, "<img class=\"paymentMethod_img\" alt=\"").concat(paymentMethod.name, "\" src=\"").concat(ccVisaLogo, "\" ></img>\n<img class=\"paymentMethod_img second-cc-image\" alt=\"").concat(paymentMethod.name, "\" src=\"").concat(ccMCLogo, "\" ></img>\n </label>\n  </div>");
  }
  // PAP-1826: EMEA - checkout - remove iDEAL placeholder text
  if (description && !isIdeal) {
    liContents += "<p>".concat(description, "</p>");
  }

  var container = document.createElement('div');
  li.innerHTML = liContents;
  li.classList.add('paymentMethod');
  //PAP-3145: add payment method type as class name
  li.classList.add(paymentMethod.type);

  if (storedPaymentMethodBool) {
    node = checkout.create('card', paymentMethod);

    if (!componentsObj[paymentMethodID]) {
      componentsObj[paymentMethodID] = {};
    }

    componentsObj[paymentMethodID].node = node;
  } else {
    var fallback = getFallback(paymentMethod.type);

    if (fallback) {
      var template = document.createElement('template');
      template.innerHTML = fallback;
      container.append(template.content);
    } else {
      try {
        node = checkout.create(paymentMethod.type);

        if (!componentsObj[paymentMethodID]) {
          componentsObj[paymentMethodID] = {};
        }

        componentsObj[paymentMethodID].node = node;
      } catch (e) {} // eslint-disable-line no-empty

    }
  }

  container.classList.add('additionalFields');
  container.setAttribute('id', "component_".concat(paymentMethodID));
  container.setAttribute('style', 'display:none');
  li.append(container);
  paymentMethodsUI.append(li);

  if (paymentMethod.type !== 'paywithgoogle') {
    node && node.mount(container);
  } else {
    node.isAvailable().then(() => {
      node.mount(container);
    }).catch(() => {}); // eslint-disable-line no-empty
  }

  var input = document.querySelector("#rb_".concat(paymentMethodID));

  input.onchange = event => {
    displaySelectedMethod(event.target.value);
  };
  
  if (paymentMethodID === 'giropay') {
    container.innerHTML = '';
  }

  if (componentsObj[paymentMethodID] && !container.childNodes[0]) {
    componentsObj[paymentMethodID].isValid = true;
  }
} // eslint-disable-next-line no-unused-vars

function addPosTerminals(terminals) {
  var terminalSelect = document.createElement('select');
  terminalSelect.id = 'terminalList';

  for (var t in terminals) {
    var option = document.createElement('option');
    option.value = terminals[t];
    option.text = terminals[t];
    terminalSelect.appendChild(option);
  }

  document.querySelector('#adyenPosTerminals').append(terminalSelect);
}

function resetPaymentMethod() {
  $('#requiredBrandCode').hide();
  $('#selectedIssuer').val('');
  $('#adyenIssuerName').val('');
  $('#dateOfBirth').val('');
  $('#telephoneNumber').val('');
  $('#gender').val('');
  $('#bankAccountOwnerName').val('');
  $('#bankAccountNumber').val('');
  $('#bankLocationId').val('');
  $('.additionalFields').hide();
}

/**
 * Makes an ajax call to the controller function GetPaymentMethods
 */
 function getPaymentMethods(paymentMethods) {
  var url = $('#adyen-component-content').data('getadyenpayments');
  $.ajax({
    url: url,
    type: 'get',
    success: function success(data) {
      paymentMethods(data);
      //PAP-3145: start
      if (typeof updateSorting !== 'undefined' && typeof updateSorting === 'function') {
        //PAP-3145 - update payment methods sorting, function defined in custom object EMEACheckoutCustomCSS 
        updateSorting();
      }
      //PAP-3145: hide paynow if sofort payment method is available
      if ($('.paymentMethod.directEbanking').length > 0 && $('.paymentMethod.klarna_paynow').length > 0) {
        $('.paymentMethod.klarna_paynow').addClass('d-none');
      }
      var currentStage = location.search.substring(
        location.search.indexOf('=') + 1
      );
      //PAP-3145: If paypal is updated in sorting, find and select payment method with minimum order value
      if (currentStage == 'payment' && $('#paymentMethodsList').find('.paypal-option-wrapper').length > 0) {
        var paymentOrder = 15;
        var firstPaymentMethod;
        $('#paymentMethodsList').find('.paymentMethod').each(function (i, paymentMethod) {
          var style = getComputedStyle(paymentMethod);
          if (style && style.order < paymentOrder) {
              paymentOrder = style.order;
              firstPaymentMethod = paymentMethod;
          }
        });
        $(firstPaymentMethod).find('input').trigger('click');
      }
      //PAP-3145: end
    },
    error: function error() {
      var currentStage = location.search.substring(
        location.search.indexOf('=') + 1
      );
      if (currentStage === 'payment') {
          displayPaymentError();
      }
    }
  });
}

/**
 * Makes an ajax call to the controller function PaymentFromComponent. Used by certain payment methods like paypal
 */
function paymentFromComponent(data, component) {
  var url = $('#adyen-component-content').data('paymentfromcomponent');
  $.ajax({
    url: url,
    type: 'post',
    data: {
      data: JSON.stringify(data)
    },
    success: function success(data) {
      if (data.fullResponse && data.fullResponse.action) {
        component.handleAction(data.fullResponse.action);
      } else {
        component.setStatus('ready');
        component.reject('Payment Refused');
      }
    }
  }).fail(function () {});
} // Submit the payment

$('button[value="submit-payment"]').off('click');

$('button[value="submit-payment"]').on('click', function () {
  if (document.querySelector('#selectedPaymentOption').value === 'AdyenPOS') {
    document.querySelector('#terminalId').value = document.querySelector('#terminalList').value;
    return true;
  } else if (document.querySelector('#selectedPaymentOption').value === 'GIFT_CERTIFICATE') {
      return true;
  } else if (document.querySelector('#selectedPaymentOption').value === 'AMAZON_PAY') {
      return true;
  } else if (document.querySelector('#selectedPaymentOption').value === 'PayPal') {
    return true;
  } else if (document.querySelector('.grand-total-sum').getAttribute('data-grand-total-sum') == 0) {
      return true; // coupon/promotion fully covers cost of purchase
  }

  assignPaymentMethodValue();
  validateComponents();
  return showValidation();
});

function assignPaymentMethodValue() {
  if (document.querySelector("#lb_".concat(selectedMethod))) {
     var adyenPaymentMethod = document.querySelector('#adyenPaymentMethodName');
     adyenPaymentMethod.value = document.querySelector("#lb_".concat(selectedMethod)).innerHTML;
  } 
}

function showValidation() {
  var input;

  if (componentsObj[selectedMethod] && !componentsObj[selectedMethod].isValid) {
    componentsObj[selectedMethod].node.showValidation();
    return false;
  }

  if (selectedMethod === 'ach') {
    var inputs = document.querySelectorAll('#component_ach > input');
    inputs = Object.values(inputs).filter(function (input) {
      return !(input.value && input.value.length > 0);
    });

    for (input of inputs) {
      input.classList.add('adyen-checkout__input--error');
    }

    if (inputs.length > 0) {
      return false;
    }

    return true;
  }

  if (selectedMethod === 'ratepay') {
    input = document.querySelector('#dateOfBirthInput');

    if (!(input.value && input.value.length > 0)) {
      input.classList.add('adyen-checkout__input--error');
      return false;
    }

    return true;
  }

  return true;
}

function validateCustomInputField(input) {
  if (input.value === '') {
    input.classList.add('adyen-checkout__input--error');
  } else if (input.value.length > 0) {
    input.classList.remove('adyen-checkout__input--error');
  }
}

/**
 * Assigns stateData value to the hidden stateData input field so it's sent to the backend for processing
 */
function validateComponents() {
  if (document.querySelector('#component_ach')) {
    var inputs = document.querySelectorAll('#component_ach > input');

    for (var input of inputs) {
      input.onchange = function () {
        validateCustomInputField(this);
      };
    }
  }

  if (document.querySelector('#dateOfBirthInput')) {
    document.querySelector('#dateOfBirthInput').onchange = function () {
      validateCustomInputField(this);
    };
  }

  var stateData;

  if (componentsObj[selectedMethod] && componentsObj[selectedMethod].stateData) {
    stateData = componentsObj[selectedMethod].stateData;
  } else {
    stateData = {
      paymentMethod: {
        type: selectedMethod
      }
    };
  }

  if (selectedMethod === 'ach') {
    var bankAccount = {
      ownerName: document.querySelector('#bankAccountOwnerNameValue').value,
      bankAccountNumber: document.querySelector('#bankAccountNumberValue').value,
      bankLocationId: document.querySelector('#bankLocationIdValue').value
    };
    stateData.paymentMethod = _objectSpread(_objectSpread({}, stateData.paymentMethod), {}, {
      bankAccount: bankAccount
    });
  } else if (selectedMethod === 'ratepay') {
    if (document.querySelector('#genderInput').value && document.querySelector('#dateOfBirthInput').value) {
      stateData.shopperName = {
        gender: document.querySelector('#genderInput').value
      };
      stateData.dateOfBirth = document.querySelector('#dateOfBirthInput').value;
    }
  }

  document.querySelector('#adyenStateData').value = JSON.stringify(stateData);
}

/**
 * Contains fallback components for payment methods that don't have an Adyen web component yet
 */
function getFallback(paymentMethod) {
  var ach = "<div id=\"component_ach\">\n                    <span class=\"adyen-checkout__label\">Bank Account Owner Name</span>\n                    <input type=\"text\" id=\"bankAccountOwnerNameValue\" class=\"adyen-checkout__input\">\n                    <span class=\"adyen-checkout__label\">Bank Account Number</span>\n                    <input type=\"text\" id=\"bankAccountNumberValue\" class=\"adyen-checkout__input\" maxlength=\"17\" >\n                    <span class=\"adyen-checkout__label\">Routing Number</span>\n                    <input type=\"text\" id=\"bankLocationIdValue\" class=\"adyen-checkout__input\" maxlength=\"9\" >\n                 </div>";
  var ratepay = "<span class=\"adyen-checkout__label\">Gender</span>\n                    <select id=\"genderInput\" class=\"adyen-checkout__input\">\n                        <option value=\"MALE\">Male</option>\n                        <option value=\"FEMALE\">Female</option>\n                    </select>\n                    <span class=\"adyen-checkout__label\">Date of birth</span>\n                    <input id=\"dateOfBirthInput\" class=\"adyen-checkout__input\" type=\"date\"/>";
  var fallback = {
    ach: ach,
    ratepay: ratepay
  };
  return fallback[paymentMethod];
}

function displayPaymentError() {
  if ($('.toast-messages').length === 0) {
    $('body').append('<div class="toast-messages"></div>');
  }

  $('.toast-messages').append('<div class="alert text-center text-center alert-danger">A timeout has occurred, please refresh the page to reload the form.</div>');

  setTimeout(function () {
      $('.toast-messages').remove();
  }, 5000);
}

function init() {
  $(document).ready(function () {
  // eslint-disable-line
  var name = 'paymentError';
  var error = new RegExp("[?&]".concat(encodeURIComponent(name), "=([^&]*)")).exec(location.search // eslint-disable-line no-restricted-globals
  );
  var paymentStage = new RegExp('[?&]stage=payment([^&]*)').exec(location.search // eslint-disable-line no-restricted-globals
  );

  if (error || paymentStage) {
    if (error) {
      $('.error-message').show();
      $('.error-message-text').text(decodeURIComponent(error[1]));
    }
    renderGenericComponent();
  }

  $('#selectedPaymentOption').val($('.payment-options .nav-item .active').parent().attr('data-method-id'));
  });

  $('.payment-options .nav-link').click(function () {
    $('input[name*="billing_paymentMethod"]').val($(this).parent().attr('data-method-id'));
  });

  $(document).on('click','div.payment-summary button.edit-button',function (e) {
    var paymentMethodsList = $('ul#paymentMethodsList li');
    if(paymentMethodsList.length == 0) {
      //render payment methods
      renderGenericComponent();
    }
  });

  $(document).on('click','a.nav-link.paypal-tab',function (e) {
    selectedMethod = '';
    $('.paymentMethod #component_scheme').css("display", "none");
    $('.paypal-content.js_paypal-content').css("display", "block");
    $('.adyen-component-content').removeClass('active');
    $('.paypal-content.js_paypal-content').addClass('active tab-pane');
    $('input[name*="billing_paymentMethod"]').val('PayPal');
    if($("#restPaypalAccountsList").val() == 'newaccount' && !$(".credit-card-selection-new").hasClass("d-none")){
      $(".submit-payment").hide();
    } else{
      $(".submit-payment").show();
    }
  });

  $(document).on('click','a.nav-link.paypal-tab',function (e) {
    selectedMethod = '';
    $('.paymentMethod #component_scheme').css("display", "none");
    $('.paypal-content.js_paypal-content').css("display", "block");
    $('.adyen-component-content').removeClass('active');
    $('.paypal-content.js_paypal-content').addClass('active tab-pane');
    $('input[name*="billing_paymentMethod"]').val('PayPal');
    if($("#restPaypalAccountsList").val() == 'newaccount' && !$(".credit-card-selection-new").hasClass("d-none")){
      $(".submit-payment").hide();
    } else{
      $(".submit-payment").show();
    }
  });

  $(document).on('click','.tab-content a.nav-link',function (e) {
    selectedMethod = '';
    if ($(this).hasClass('amazon_pay-tab')) {
      var methodID = $(this).data('method-id');
      $('.paymentMethod #component_scheme').css("display", "none");
      $('.adyen-component-content').removeClass('active');
      $('.payment-information').data('payment-method-id', methodID);
      sessionStorage.setItem('paymentMethodId', methodID);
      $('input[name*="billing_paymentMethod"]').val('AMAZON_PAY');
      $('.amazon_pay-message').removeClass('d-none');
      $(".submit-payment").show();
      // setTimeout(function(){
        $("#amazonCustomTab").prop("checked",true);
      // },100)  
    } else {
      sessionStorage.setItem('paymentMethodId', '');
      $('.amazon_pay-message').addClass('d-none');
    }
  });

  // PAP-1675: Order getting fail due to missing payment method info
  // Paypal radio button not selected if user select credit card or other payment method- issue fixed
  $('div.paypal-option-wrapper').on('click',function(){
    setTimeout(function(){
      $("#paypalCustomTab").prop("checked",true)
    },100)    
  });
  
  
  $(document).on('click','.adyen-component-content #paymentMethodsList .paymentMethod input, .adyen-component-content #paymentMethodsList .paymentMethod label',function (e) {
    $('.paypal-content.js_paypal-content').css("display", "none");
    $('.paypal-content.js_paypal-content').removeClass('active tab-pane');
    if (this.value == 'scheme') {
      $('.paymentMethod #component_scheme').css("display", "block");
    }
    $('.adyen-component-content').addClass('active');
    $('input[name*="billing_paymentMethod"]').val('AdyenComponent');
    sessionStorage.setItem('paymentMethodId', '');
    $('.amazon_pay-message').addClass('d-none');
    $(".submit-payment").show();
  });

  $("#restPaypalAccountsList").change(function(){
    var selectedVal = $("#restPaypalAccountsList").val();
    if(selectedVal == 'newaccount' && !$(".credit-card-selection-new").hasClass("d-none")){
      $(".submit-payment").hide();
    } else{
      $(".submit-payment").show();
    }
  });

}

integrations.methods.renderGenericComponent = renderGenericComponent;
integrations.methods.init = init;

module.exports = integrations;
