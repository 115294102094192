'use strict';

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
 var focusHelper = require('base/components/focus');
 var modal = require('../components/modal');
 const { updateAddAllToCart } = require('./detail');
 var countdownTimer = require('../thirdParty/countdownTimer');

function fillModalElement(selectedValueUrl) {
    var $modal = $('#quickViewModal');
    $modal.find('.modal-body').spinner().start();
    var url = selectedValueUrl;
    // URL stored in session storage has query param to remove upsell prootion, take this url for ajax call when promo time is elapsed
    var upsellPromoTimeElapsed = window.sessionStorage.getItem('upsellPromoTimeElapsed');
    if (upsellPromoTimeElapsed && window.sessionStorage.getItem('upsellPromoTimeElapsed') == 'true') {
        url = window.sessionStorage.getItem('Upsell_Url');
    }
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'json',
            success: function(data) {
                var parsedHtml = modal.parseHtml(data.renderedTemplate);

                $modal.find('.modal-body').empty();
                $modal.find('.modal-body').html(parsedHtml.body);
                $modal.find('.modal-footer').html(parsedHtml.footer);
                $modal.find('.full-pdp-link').text(data.quickViewFullDetailMsg);
                $modal.find('.full-pdp-link').attr('href', data.productUrl);
                $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
                $modal.find('.modal-header .quickviewHeading').text(data.headingText);
                $modal.find('.enter-message').text(data.enterDialogMessage);
                $modal.find('#quickViewModal').modal('show');

                if (data.dialogTitle) {
                    $modal.find('.modal-header').prepend('<h2 class="modal-title">' + data.dialogTitle + '</h2>') //PAP-1119 changed div to h2
                }

                if (data.product.productType === 'set') {
                    updateAddAllToCart();
                }

                $('body').trigger('modal:loaded', $('#quickViewModal')); // sending custom event for scroll body sizing
                $('body').trigger('quickview:ready', $('#quickViewModal')); // sending custom event for slider init
                $('body').trigger('tooltip:init');

                // Timer code starts here, check if upsell modal promo feature is enabled
                if (data.upsellPromotionApplicable) {
                    // check if timer is already running and skip it
                    var isTimerRunning = window.sessionStorage.getItem('UpsellCounterStarted');
                    if (!isTimerRunning) {
                        var countDownTime = window.sessionStorage.getItem('COUNTER_KEY') || window.CachedData.siteIntegrations.upsellPromoCountDownTimer;
                        if (selectedValueUrl.includes('upsell')) {
                            // Add query param in url to remove promo which will be used once time is elapsed
                            selectedValueUrl = selectedValueUrl + '&upsellPromoApplicable=false';
                        }
                        window.sessionStorage.setItem('Upsell_Url', selectedValueUrl);
                        // timer starts here
                        countdownTimer.countDown(Number(countDownTime));
                    }
                }

                $.spinner().stop();
            },
            error: function() {
                $.spinner().stop();
            }
        });
    }
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            $(e.target).trigger('quickview:show');
            modal.getModalHtmlElement('quickViewModal', 'quick-view-dialog');
            fillModalElement(selectedValueUrl);
        });
    },
    focusQuickview: function() {
        $('body').on('shown.bs.modal', '#quickViewModal', function() {
            $('#quickViewModal').siblings().attr('aria-hidden', 'false');
            $('#quickViewModal .close').focus();
        });
    },
    trapQuickviewFocus: function() {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.qv-product-edit-add',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    showSpinner: function() {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function() {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
            $('#quickViewModal').siblings().attr('aria-hidden', 'true');
        });
    },
    beforeUpdateAttribute: function() {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    fillModalElement: fillModalElement
};
