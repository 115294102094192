'use strict';

/**
 * flyout cached elements
 */
var $document = $(document);
/**
 * open function flyout
 * @param {element} target - clickable element
 */
function openFlyout(target) {
    var $this = $(target);
    var flyoutContainer = $('[data-flyout-container]');
    var flyoytWrapper = $('[data-flyout-wrapper]');
    var flyoutVeild = $('.modal-background');


    $('html').addClass('scroll-direction-up');
    $('body').addClass('flyout-opened');
    $('.navbar').addClass('disabled');
    $('.search.hidden-md-down').addClass('disabled'); 
    flyoutVeild.addClass('flyout-veil-bg').show();
    $this.addClass('flyout-active');
    $this.closest(flyoutContainer).addClass('flyout-active').find(flyoytWrapper).addClass('flyout-show');
}

/**
 * close function flyout
 * @param {element} target - clickable element
 */
function closeFlyout(target) {
    var $this = $(target);
    var flyoutContainer = $('[data-flyout-container]');
    var flyoytWrapper = $('[data-flyout-wrapper]');
    var flyoutOpener = $('[data-flyout-opener]');

    if ($('body').hasClass('flyout-opened')) {
        $('body').removeClass('flyout-opened');
    }

    if ($this.hasClass('flyout-veil-bg')) {
        $this.hide().removeClass('flyout-veil-bg');
    }

    if ($this.hasClass('data-flyout-close')) {
        $this.closest('body').find('.flyout-veil-bg').hide().removeClass('flyout-veil-bg');
    }

    if (flyoutOpener.hasClass('flyout-active')) {
        flyoutOpener.removeClass('flyout-active');
    }

    if (flyoutContainer.hasClass('flyout-active')) {
        flyoutContainer.removeClass('flyout-active');
    }

    if (flyoytWrapper.hasClass('flyout-show')) {
        flyoytWrapper.removeClass('flyout-show');
    }

    if ($('.navbar').hasClass('disabled')) {
        $('.navbar').removeClass('disabled');
    }

    if ($('.search.hidden-md-down').hasClass('disabled')) {
        $('.search.hidden-md-down').removeClass('disabled');
    }
}

module.exports = function () {
    $document.on('click', '[data-flyout-opener]', function () {
        openFlyout($(this));
    });

    $document.on('click', '.data-flyout-close', function () {
        closeFlyout($(this));
    });

    $document.on('click', '.flyout-veil-bg', function () {
        closeFlyout($(this));
    });

    $('body').on('touchstart click', function (e) {
        if (!$('body').hasClass('modal-open')) {
            if ($('.minicart.flyout-enabled').has(e.target).length <= 0) {
                closeFlyout($(this));
            }
        }
    });

    $document.on('click', '[modal-data-flyout-opener]', function () {
        $('body').find('#product-customizer-popup button.close').click();
        $('body').find('.minicart-total .minicart-link').click();
    });
};
