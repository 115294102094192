'use strict';

module.exports = () => {
    $(document).ready(function() {
        $('[data-date]').each(function() {
            var $this = $(this), finalDate = $(this).data('date');
            $this.countdown(finalDate, function(event) {
                var $this = $(this).html(event.strftime(''
                + '<span>%m</span> Month%!d '
                + '<span>%-d</span> day%!d '
                + '<span>%H</span> hr '
                + '<span>%M</span> min '
                + '<span>%S</span> sec'));
            });
        });
    });
}