'use strict';
/** PAP-616
 * This file extends rvw_autobahn_core\cartridge\client\default\js\components\einsteinRecs.js function loadRecommendations()
 * add new function checkEinsteinProducts to check products coming from Einstein
 */
var base = require('core/components/einsteinRecs');
var einsteinUtils = require('core/components/einsteinUtils');
var productTile = require('core/components/productTile');
var abSlider = require('./slider');

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 * @param {Object} e jquery event
 * @returns {Object} jquery targets
 */
 base.loadRecommendations = function (e) {
    var $context = $(this);
    var $recommendationTargets = $context.hasClass('.einstein-data') ? $context : $context.find('.einstein-data');

    return $recommendationTargets.each(function () {
        var $recTrigger = $(this);
        var data = $recTrigger.data() || {};
        var asyncUrl = data.url;
        einsteinUtils.getRecommendations(data, function (einsteinResponse) {
            var response = einsteinUtils.interpretResponse(data, einsteinResponse);
            //PAP-616 check product details coming from einstein response through AJAX
            var products = checkEinsteinProducts(response);
            // Add replace content URL
            $.each(response, function (i, rec) {
                if (!rec || !rec.id || products.indexOf(rec.id) == -1) return null;

                var pidURL = asyncUrl;
                pidURL += (pidURL.indexOf('?') === -1 ? '?' : '&') + 'pid=' + rec.id;
                $recTrigger.find('.product:not([data-replace-content])')
                    .first()
                    .attr('data-replace-content', pidURL); //Must be attr to allow productTile.js async selector rule to catch recommendation
            });

            // Remove empty slides
            $recTrigger.find('.slide:has(.product:not([data-replace-content]))').remove();

            // Prepare Slider Container
            var $sliderContainer = $recTrigger.closest('.slider-container').removeClass('delay-init');

            //Trigger ASYNC update
            productTile.init($recTrigger.find('.product'));

            // Initialize sliders
            abSlider.initializeSliders($sliderContainer.parent());
        });
    });
}

/**
 * get product details coming from einstein response
 * @param {Object} e einsteinResponse
 * @returns {Object} product ids
 */
function checkEinsteinProducts(einsteinResponse) {
    var pids = '';
    var url = $('div.product-tile').data('url');

    $.ajax({
        url: url,
        headers: {
            'content-type': 'application/json'
        },
        dataType: 'json',
        method: 'post',
        async: false,
        data: JSON.stringify(einsteinResponse),
        success: function success(data) {
            pids = data.availableProducts;
        }
    });

    return pids;
}

module.exports = base;